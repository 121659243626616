const BELGIUM = 'Belgique';
const LUX = 'Luxembourg';
const FRANCE = 'France';
const NEDERLANDS = 'Pays-Bas';
const SPAIN = 'Espagne';

export default {
  BELGIUM,
  LUX,
  FRANCE,
  NEDERLANDS,
  SPAIN,
}
