import React from 'react';

import Layout from '@heureca/shared/components/Layout';
import Steps from '@heureca/steps/components/Steps';
import Hero from '@heureca/hero/components/Hero';
import Trusted from '@heureca/trusted/components/Trusted';
import Benefits from '@heureca/benefits/components/Benefits';
import Pricing from '@heureca/pricing/components/Pricing';
import About from '@heureca/about-us/components/About';
import Contact from '@heureca/contact/components';

const IndexPage = () => (
  <Layout pageTitle="Accueil">
    <Hero />
    <Trusted />
    <Steps />
    <Benefits />
    <Pricing />
    <About />
    <Contact />
  </Layout>
);

export default IndexPage;
