import React from 'react';
import classNames from 'classnames';

import * as styles from './pricingCard.module.scss';

interface Props {
  price: number;
  currency: string;
  description: string;
}

const Price = (props: Props) => {
  const { price, currency, description } = props;

  return (
    <div className={styles.price_amount}>
      <span className={classNames('h1', styles.price_number)}>
        {price}
      </span>
      <span className={classNames('h1', styles.price_symbol)}>{currency}</span>
      <div className={styles.price_occurrence}>{description}</div>
    </div>
  );
};

export default Price;
