import bergerie from './bergerie.png'
import depooter from './depooter.png'
import lolipop from './lolipop.png'
import rebelge from './rebelge.png'
import rougeGourmand from './rouge_gourmand.png'

const trusted = {
  bergerie,
  depooter,
  lolipop,
  rebelge,
  rougeGourmand,
};

export default trusted;
