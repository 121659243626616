// extracted by mini-css-extract-plugin
export var section = "hero-module--section--9N7Mh";
export var background = "hero-module--background--1TxAQ";
export var sectionContent = "hero-module--sectionContent---pImm";
export var heureca = "hero-module--heureca--36F32";
export var content = "hero-module--content--3x1S7";
export var title = "hero-module--title--u6Cn-";
export var colored = "hero-module--colored--2ULFG";
export var logo = "hero-module--logo--3FPxN";
export var button = "hero-module--button--3gryZ";
export var iconScroll = "hero-module--iconScroll--2tVG9";
export var scroll = "hero-module--scroll--3q0Ii";