import countryConstants from '@heureca/signUp/constants/country.constants';

export const isEmailValid = (email: string) => /\S+@\S+\.\S+/.test(email);

const isVATNumberValid = (vatNumber: string, country: string) => {
  if (country !== countryConstants.BELGIUM) {
    return true;
  }

  const vatNumberWithoutCS = vatNumber
    .substr(2, vatNumber.length - 4)
    .replace(/\./g, '');

  const checkSum = vatNumber
    .replace(/\./g, '')
    .substr(vatNumber.length - 4, vatNumber.length);

  return (97 - (parseInt(vatNumberWithoutCS, 10) % 97)) === parseInt(checkSum, 10);
};

export default {
  isVATNumberValid,
  isEmailValid,
};
