/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import { FieldError, Path, UseFormRegister } from 'react-hook-form';
import IFormValues from '@heureca/shared/models/IFormValues.model';

import * as styles from './textArea.module.scss';

interface Props {
  placeholder: string;
  label: string;
  name: Path<IFormValues>;
  validate?: (value: string) => boolean;
  rows?: number;
  register?: UseFormRegister<IFormValues>;
  required?: boolean;
  value?: string;
  customErrorMessage?: string;
  error?: FieldError;
}

const TextArea = (props: Props) => {
  const {
    label,
    rows = 10,
    register,
    required,
    value,
    validate,
    name,
    error,
    customErrorMessage,
    placeholder,
  } = props;

  return (
    <div className={styles.inputWrapper} style={{ width: '100%' }}>
      <label htmlFor={label} className={styles.label}>
        {label}
        {required && <span className={styles.required}>*</span>}
      </label>
      <textarea
        placeholder={placeholder}
        name={name}
        value={value}
        className={styles.input}
        rows={rows}
        {...register(name, { required, validate })}
      />
      <p className={classNames({ [styles.errorMessage]: !error })}>
        {customErrorMessage || "Le champ n'est pas valide"}
      </p>
    </div>
  );
};

export default TextArea;
