import React from 'react';

import Section from '@heureca/shared/components/Section';
import steps from '@data/steps.data.json';

import Illustration1 from '@assets/images/steps/step-1.svg';
import Illustration2 from '@assets/images/steps/step-2.svg';
import Illustration3 from '@assets/images/steps/step-3.svg';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';

import StepCard from '../StepCard';

import * as styles from './steps.module.scss';

const illustrations = {
  step1: Illustration1,
  step2: Illustration2,
  step3: Illustration3,
};

const Steps = () => {
  const { translate } = useTranslations();

  return (
    <Section name="steps" title={translate('steps.title')} contentClassNames={styles.content}>
      {Object.entries(steps).map(([key], index) => (
        <div key={key} className={styles.step}>
          <img
            className={styles.illustration}
            key={key}
            src={illustrations[key]}
            alt={translate(`steps.${key}.title`)}
          />
          <StepCard
            classNames={styles.card}
            index={index + 1}
            title={translate(`steps.${key}.title`)}
            description={translate(`steps.${key}.description`)}
          />
        </div>
      ))}
    </Section>
  );
};

export default Steps;
