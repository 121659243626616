import React from 'react';

import Section from '@heureca/shared/components/Section';
import AboutCard from '@heureca/about-us/components/AboutCard';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';
import AvenirLogo from '@assets/images/lavenirLogo.svg';
import LaProvinceLogo from '@assets/images/laprovinceLogo.svg';
import TeleMbLogo from '@assets/images/telembLogo.png';
import AboutIllustration from '@assets/images/aboutIllustration.svg';
import newspapers from '@data/newspapers.data.json';

import * as styles from './about.module.scss';

const logos = [AvenirLogo, LaProvinceLogo, TeleMbLogo];

const About = () => {
  const { translate } = useTranslations();

  return (
    <Section
      name="about"
      title={translate('about.title')}
      sectionClassNames={styles.section}
      contentClassNames={styles.sectionContent}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.videosWrapper}>
          <div className={styles.videoCard}>
            <iframe
              title={translate('about.vivacite')}
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/4GMw6-Htsyw"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div className={styles.videoCard}>
            <iframe
              title={translate('about.telemb')}
              src="https://telemb.fcst.tv/player/embed/3423010"
              width="100%"
              height="100%"
              frameBorder="0"
              scrolling="no"
              allowFullScreen
            />
          </div>
        </div>
        {newspapers.map((e, i) => (
          <div className={styles.aboutCard} key={e.name}>
            <AboutCard
              logo={logos[i]}
              name={e.name}
              description={e.description}
              link={e.link}
            />
          </div>
        ))}
      </div>
      <img src={AboutIllustration} alt="" className={styles.illustration} />
    </Section>
  );
};

export default About;
