import React from 'react';

import Section from '@heureca/shared/components/Section';
import benefits from '@data/benefits.data.json';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';

import BenefitCard from '../BenefitCard';
import * as styles from './benefits.module.scss';

const color = {
  0: '#FFF3C9',
  1: '#D4FFE8',
  2: '#D7F6FF',
  3: '#F3E5FF',
  4: '#FFE5E1',
  5: '#FBFFD4',
};

const iconColor = {
  0: '#FFC700',
  1: '#53FFA3',
  2: '#0EBFF2',
  3: '#A944FF',
  4: '#FF563C',
  5: '#D6E829',
};

const Benefits = () => {
  const { translate } = useTranslations();

  return (
    <Section
      name="benefits"
      title={translate('sections.benefits')}
      sectionClassNames={styles.section}
      contentClassNames={styles.content}
    >
      {benefits.map((b, index) => (
        <BenefitCard
          key={b.name}
          benefit={{
            title: translate(`benefits.${b.name}.title`),
            description: translate(`benefits.${b.name}.description`),
            icon: b.icon,
          }}
          color={color[index]}
          iconColor={iconColor[index]}
        />
      ))}
    </Section>
  );
};

export default Benefits;
