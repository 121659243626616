import React from 'react';
import classnames from 'classnames';

import * as styles from './stepCard.module.scss';

interface Props {
  index: number;
  title: string;
  description: string;
  classNames?: string;
}

const StepCard = (props: Props) => {
  const { index, title, description, classNames } = props;

  return (
    <div className={classnames(classNames, styles.stepCard)}>
      <div className={styles.cardNumber}>
        <span>{index}</span>
      </div>
      <h3 className="h1">{title}</h3>
      <p>{description}</p>
    </div>
  );
}

export default StepCard;
