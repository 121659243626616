import React from 'react';

import Trusted from '@heureca/trusted/models/Trusted.model';
import images from '@assets/images/trusted';

import * as styles from './trustedCard.module.scss';

interface Props {
  trusted: Trusted;
}

const { bergerie, depooter, rebelge, rougeGourmand, lolipop } = images;

const trustedImage = {
  bergerie,
  depooter,
  rebelge,
  rouge_gourmand: rougeGourmand,
  lolipop,
};

const TrustedCard = (props: Props) => {
  const { trusted } = props;
  const { logo, title, url } = trusted;

  return (
    <div className={styles.logo}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img src={trustedImage[logo]} alt={title} />
      </a>
    </div>
  );
};

export default TrustedCard;
