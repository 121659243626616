import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCreditCard,
  faClipboard,
  faCalendarAlt,
  faEdit,
  faBell,
} from '@fortawesome/fontawesome-free-regular';

import Benefit from '@heureca/benefits/models/Benefit.model';

import * as styles from './benefitCard.module.scss';

interface Props {
  benefit: Benefit;
  color: string;
  iconColor: string;
}

const icons = {
  eye: faEye,
  'credit-card': faCreditCard,
  clipboard: faClipboard,
  calendar: faCalendarAlt,
  edit: faEdit,
  bell: faBell,
};

const BenefitCard = (props: Props) => {
  const {
    benefit,
    color,
    iconColor,
  } = props;

  const {
    title,
    description,
    icon,
  } = benefit;

  return (
    <div className={styles.card} style={{ backgroundColor: color }}>
      <FontAwesomeIcon icon={icons[icon]} color={iconColor} className={styles.icon} />
      <h4>{title}</h4>
      <p>
        {description}
      </p>
    </div>
  );
};

export default BenefitCard;
