// extracted by mini-css-extract-plugin
export var card = "pricingCard-module--card--2Omaw";
export var card_header = "pricingCard-module--card_header--29pel";
export var hightlightedText = "pricingCard-module--hightlightedText--1tmm_";
export var price_amount = "pricingCard-module--price_amount--2UKqw";
export var price_number = "pricingCard-module--price_number--3-Sw7";
export var price_symbol = "pricingCard-module--price_symbol--2akAM";
export var price_occurrence = "pricingCard-module--price_occurrence--2QkmT";
export var featureList = "pricingCard-module--featureList--38ATS";
export var feature = "pricingCard-module--feature--2Zkjz";
export var strikeout = "pricingCard-module--strikeout--b9NCQ";