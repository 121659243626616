import { useContext } from 'react';

import { AlertContext } from '@heureca/app/AlertContext';


const useAlert = () => {
  const {
    addAlert,
  } = useContext(AlertContext);

  return {
    addAlert
  };
};

export default useAlert;
