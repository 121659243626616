import React, { useState } from 'react';

import pricings from '@data/pricings.data.json';
import PricingTable from '@heureca/pricing/models/PricingTable.model';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';
import Section from '@heureca/shared/components/Section';
import pricingConstants from '@heureca/pricing/constants/pricing.constants';
import recurrenceConstants from '@heureca/signUp/constants/subscriptionRecurrence.constants';
import RecurrenceChoice from '@heureca/shared/components/RecurrenceChoice';

import PricingCard from '../PricingCard';

import * as styles from './pricings.module.scss';

const highlightedPricing = pricingConstants.PRO;

const Pricing = () => {
  const { translate } = useTranslations();

  const [selectedRecurrence, setSelectedRecurrence] = useState(recurrenceConstants.YEARLY);

  return (
    <Section
      name="pricing"
      title={translate('pricing.title')}
    >
      <RecurrenceChoice
        value={selectedRecurrence}
        onChange={setSelectedRecurrence}
      />

      <p className={styles.currentRecurrence}>{translate(`pricing.recurrence.${selectedRecurrence}`)}</p>

      <div className={styles.sectionContent}>
        {pricings.map((pricing: PricingTable) => (
          <a
            className={styles.link}
            href={process.env.GATSBY_FUNNEL_URL}
            key={pricing.id}
          >
            <PricingCard
              pricing={pricing}
              recurrence={selectedRecurrence}
              hightlighted={pricing.id === highlightedPricing}
            />
          </a>
        ))}
      </div>
    </Section>
  );
};

export default Pricing;
