import React from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Section from '@heureca/shared/components/Section';
import trustedItems from '@data/trusted.data.json';
import LinkButton from '@heureca/shared/components/LinkButton';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';

import TrustedCard from '../TrustedCard';

import 'swiper/css';
import 'swiper/css/autoplay';

import * as styles from './trusted.module.scss';

SwiperCore.use([Autoplay]);

const swiperConfig = {
  autoplay: {
    delay: 1300,
    disableOnInteraction: false,
  },
  breakpoints: {
    640: {
      spaceBetween: 25,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 150,
      slidesPerGroup: 1,
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 250,
    },
  },
};

const Trusted = () => {
  const { translate } = useTranslations();

  return (
    <Section
      name="trusted"
      title={translate('trusted.title')}
      sectionClassNames={styles.section}
    >
      <Swiper
        slidesPerView={4}
        slidesPerGroup={1}
        spaceBetween={25}
        autoplay={swiperConfig.autoplay}
        breakpoints={swiperConfig.breakpoints}
        loop
      >
        {trustedItems.map((t) => (
          <SwiperSlide key={t.title} style={{ display: 'flex', justifyContent: 'center' }}>
            <TrustedCard trusted={t} />
          </SwiperSlide>
        ))}
      </Swiper>
      <LinkButton
        label={translate('trusted.link')}
        link="https://heureca.store"
        isExternal
        rel="noopener"
        classNames={styles.storeLink}
      />
    </Section>
  );
};

export default Trusted;
