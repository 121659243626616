import React from 'react';
import classnames from 'classnames';

import ChoiceModel from '@heureca/shared/models/Choice.model';

import * as styles from './choices.module.scss';

interface Props {
  value: string;
  options: ChoiceModel[];
  name: string;
  onChange: (value: string) => void;
}

const Choices = (props: Props) => {
  const {
    value,
    options,
    name,
    onChange,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className={styles.choices}>
      {options.map((c) => (
        <label
          key={c.value}
          htmlFor={c.value}
          className={classnames(styles.choice, { [styles.selected]: c.value === value })}
        >
          {c.label}

          <input
            className={styles.radio}
            id={c.value}
            name={name}
            type="radio"
            value={c.value}
            defaultChecked={c.value === value}
            onChange={handleChange}
          />
        </label>
      ))}
    </div>
  );
};

export default Choices;
