import React from 'react';

import recurrenceConstants from '@heureca/signUp/constants/subscriptionRecurrence.constants';

import Choices from './Choices';
import useTranslations from '../hooks/useTranslations.hook';
import ChoiceModel from '../models/Choice.model';

interface Props {
  value: string;
  onChange: (id: string) => void;
}

const RecurrenceChoice = (props: Props) => {
  const { value, onChange } = props;

  const { translate } = useTranslations();

  const recurrenceChoices: ChoiceModel[] = [
    {
      value: recurrenceConstants.MONTHLY,
      label: translate(`recurrences.${recurrenceConstants.MONTHLY}`),
    },
    {
      value: recurrenceConstants.YEARLY,
      label: translate(`recurrences.${recurrenceConstants.YEARLY}`),
    },
  ];

  return (
    <Choices
      value={value}
      options={recurrenceChoices}
      name="recurrences"
      onChange={onChange}
    />
  );
};

export default RecurrenceChoice;
