import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { load } from 'recaptcha-v3';
import axios from 'axios';

import IFormValues from '@heureca/shared/models/IFormValues.model';
import Section from '@heureca/shared/components/Section';
import Input from '@heureca/shared/components/Input';
import Button from '@heureca/shared/components/Button';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';
import useAlert from '@heureca/shared/hooks/useAlert';
import TextArea from '@heureca/shared/components/TextArea';
import { isEmailValid } from '@heureca/shared/utils/validators.utils';

import * as styles from './contact.module.scss';

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormValues>({ mode: 'onBlur', reValidateMode: 'onBlur' });

  const { translate } = useTranslations();
  const [emailSending, setEmailSending] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [buttonLabel, setButtonLabel] = useState(translate('contact.sendMessage'));

  const { addAlert } = useAlert();

  const onSubmit = async (formValues: Partial<IFormValues>) => {
    const { contactForm } = formValues;

    try {
      setEmailSending(true);
      const recaptcha = await load(process.env.GATSBY_RECAPTCHA_PUBLIC_KEY);
      const token = await recaptcha.execute('submit');

      await axios.post(process.env.GATSBY_EMAIL_FUNCTION_URL, {
        id: 'HEURECA',
        token,
        data: contactForm,
      });

      reset();
      setEmailSent(true);
      setButtonLabel(translate('contact.emailSent'));
      addAlert('success', translate('contact.emailSuccess'));
    } catch {
      addAlert('error', translate('contact.emailError'));
    } finally {
      setEmailSending(false);
    }
  };

  return (
    <Section name="contact" title={translate('contact.contactUs')}>
      <p className={styles.description}>
        {translate('contact.description')}
      </p>
      <div className={styles.infoWrapper}>
        <div className={styles.info}>
          <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icon} />
          <a href="https://www.google.com/maps/place/Rue+Comte+Cornet+19,+7020+Mons/@50.4862064,3.9664844,17z">
            Rue Comte Cornet, 19 - 7020 Maisières
          </a>
        </div>
        <div className={styles.info}>
          <FontAwesomeIcon icon={faPhoneAlt} className={styles.icon} />
          <a href="tel:+3265982440">+32 65 98 24 40</a>
        </div>
        <div className={styles.info}>
          <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
          <a href="mailto:hello@heureca.eu">hello@heureca.eu</a>
        </div>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Input
          label={translate('contact.name')}
          required
          register={register}
          name="contactForm.name"
          error={errors.contactForm?.name}
          placeholder={translate('contact.name')}
        />
        <Input
          label={translate('contact.shopName')}
          required
          register={register}
          name="contactForm.shopName"
          error={errors.contactForm?.shopName}
          placeholder="Ex: Heureca"
          wrapperClassName={styles.input}
        />
        <Input
          label={translate('contact.email')}
          required
          register={register}
          name="contactForm.email"
          validate={isEmailValid}
          placeholder="Ex: exemple@heureca.eu"
          error={errors.contactForm?.email}
          customErrorMessage={translate('contact.emailNotValid')}
        />
        <Input
          label={translate('contact.phoneNumber')}
          required
          register={register}
          name="contactForm.phoneNumber"
          placeholder={translate('contact.phoneNumber')}
          error={errors.contactForm?.phoneNumber}
          wrapperClassName={styles.input}
        />
        <TextArea
          label={translate('contact.message')}
          required
          register={register}
          name="contactForm.message"
          error={errors.contactForm?.message}
          placeholder={translate('contact.message')}
        />
        <Button
          label={buttonLabel}
          classNames={styles.button}
          type="submit"
          disabled={emailSent || emailSending}
        />
      </form>
    </Section>
  );
};

export default Contact;
