import React from 'react';
import classnames from 'classnames';

import Section from '@heureca/shared/components/Section';
import HeurecaLogo from '@assets/images/heurecaLogo.svg';
import HeroBackground from '@assets/images/heroBackground.png';
import LinkButton from '@heureca/shared/components/LinkButton';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';

import * as styles from './hero.module.scss';

const Hero = () => {
  const heurecaClasses = classnames('h4', styles.heureca);
  const titleClasses = classnames('h1', styles.title);
  const { translate } = useTranslations();

  return (
    <Section
      name="home"
      sectionClassNames={styles.section}
      contentClassNames={styles.sectionContent}
    >
      <img className={styles.background} src={HeroBackground} alt="" />
      <div className={styles.content}>
        <h1 className={heurecaClasses}>— Heureca</h1>
        <h2 className={titleClasses}>
          {translate('hero.startTo')}
          <span className={styles.colored}>
            {translate('hero.sellOnline')}
          </span>
          {translate('hero.now')}
        </h2>
        <p>
          {translate('hero.description')}
        </p>
        <LinkButton
          label={translate('hero.startFreeButton')}
          classNames={styles.button}
          link={process.env.GATSBY_FUNNEL_URL}
        />
      </div>
      <img src={HeurecaLogo} alt="" className={styles.logo} />
      <span className={styles.iconScroll} />
    </Section>
  );
};

export default Hero;
