import React from 'react';

import Card from '@heureca/shared/components/Card';
import * as styles from './aboutCard.module.scss';

interface Props {
  logo: string;
  name: string;
  description: string;
  link: string;
}

const AboutCard = (props: Props) => {
  const { logo, name, description, link } = props;

  return (
    <Card cardClassNames={styles.card}>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer nofollow"
        aria-label={`${name} - ${description}`}
        className={styles.link}
      >
        {' '}
      </a>
      <div className={styles.contentWrapper}>
        <div className={styles.logoWrapper}>
          <img src={logo} alt="" className={styles.logo} />
        </div>
        <p className={styles.description}>{description}</p>
      </div>
    </Card>
  );
};

export default AboutCard;
