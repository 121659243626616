import React from 'react';
import classNames from 'classnames';

import PricingTable from '@heureca/pricing/models/PricingTable.model';
import Card from '@heureca/shared/components/Card';
import pricingConstants from '@heureca/pricing/constants/pricing.constants';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';
import recurrenceConstants from '@heureca/signUp/constants/subscriptionRecurrence.constants';

import * as styles from './pricingCard.module.scss';
import Price from './Price';

interface Props {
  pricing: PricingTable;
  hightlighted?: boolean;
  recurrence: string;
}

const PricingCard = (props: Props) => {
  const { pricing, hightlighted, recurrence } = props;

  const { features } = pricing;
  const { translate } = useTranslations();

  const price = recurrence === recurrenceConstants.YEARLY
    ? pricing.yearlyPrice
    : pricing.monthlyPrice;

  return (
    <Card
      cardClassNames={classNames(styles.card, { [styles.hightlighted]: hightlighted })}
    >
      {pricing.id === pricingConstants.PRO && (
        <span className={styles.hightlightedText}>
          {translate('pricing.bestChoice')}
        </span>
      )}
      {pricing.id === pricingConstants.COMMISSION && (
        <span className={styles.hightlightedText}>
          {translate('pricing.freeStart')}
        </span>
      )}
      <div className={styles.card_header}>
        <h3 className="h1">
          {translate(`pricing.plan.${pricing.id}`)}
        </h3>
        <div>
          {pricing.id === pricingConstants.PRO && (
            <Price price={price} currency="€" description={translate('pricing.htvaMonth')} />
          )}
          {pricing.id === pricingConstants.COMMISSION && (
            <Price price={pricing.commissionFees} currency="%" description={translate('pricing.commissionFees')} />
          )}
        </div>
      </div>
      <ul className={styles.featureList}>
        {features.map((feature) => (
          <li
            key={feature.name}
            className={classNames(styles.feature, { [styles.strikeout]: !feature.isActive })}
          >
            {translate(`pricing.features.${feature.name}`)}
          </li>
        ))}
      </ul>
    </Card>
  );
};

export default PricingCard;
